<template>
  <!-- 收费员账号 -->
  <div class="ParkingInformation">
    <div class="box-card" style="text-align:left;">
         <!-- 查询表单 -->
         <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" @submit.native.prevent>
              <el-form-item label="用户名：   ">
            <el-input v-model="queryForm.account" maxlength="11" @keyup.enter.native="onSubmit()" @input="(e) => (queryForm.account = validSpace(e))" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="姓名：">
            <el-input v-model="queryForm.user_name" @keyup.enter.native="onSubmit()" @input="(e) => (queryForm.user_name = validSpace(e))" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号：">
            <el-input v-model="queryForm.phone" @keyup.enter.native="onSubmit()" maxlength="11" @input="(e) => (queryForm.phone = validSpace(e))" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="停车场：">
            <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option placeholder="请选择停车场" @change="onSubmit">
              <el-option v-for="item in getAllParkingList()" :key="item.parking_id" :label="item.parking_name" :value="item.parking_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select popper-class="my-select" v-model="queryForm.online_status" filterable clearable default-first-option placeholder="请选择状态" @change="onSubmit()">
              <el-option label="在线" :value="1"></el-option>
              <el-option label="离线" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
          </el-form-item>
          <!-- <el-form-item label="考勤组：">
            <el-select popper-class="my-select" v-model="queryForm.attendance_group_id" filterable clearable
              default-first-option placeholder="请选择考勤组" @change="onSubmit()">
              <el-option v-for="item in attendanceGroupQueryList" :key="item._id" :label="item.attendance_group_name"
                :value="item._id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="围栏策略：">
            <el-select popper-class="my-select" v-model="queryForm.ring_fence_id" filterable clearable
              default-first-option placeholder="请选择围栏策略" @change="onSubmit()">
              <el-option v-for="item in railStrategyQueryList" :key="item.ring_fence_id" :label="item.strategy"
                :value="item.ring_fence_id">
              </el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
    <le-card title="收费员账号">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('635b4fa6b10b00004400719e')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="openAddForm()" v-if='this.getUserInfo.user_name != "admin" && isShowBtn("635b4e39b10b000044007198")'>新增</lbButton>
        </div>
      </template>
        <!-- 主要内容 -->
        <div>
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="online_status">
            <div :style="row.online_status == 0 ? 'color:#aaa;' : 'color: #67C23A;'">{{ row.online_status
              == 0 ? '离线' : '在线'
              }}</div>
          </template>
           <template slot-scope="{row}" slot="last_logintime">
            {{ validDateTime(row.last_logintime) }}
          </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(row)" v-if="isShowBtn('635b4fa6b10b00004400719d')"></lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" tooltip-effect="dark" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column prop="account" label="用户名" min-width="100"></el-table-column>
          <el-table-column prop="user_name" label="姓名" min-width="100"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="parking_name" label="停车场" min-width="100"></el-table-column>
          <el-table-column label="状态" width="90">
            <template slot-scope="scope">
              <div :style="scope.row.online_status == 0 ? 'color:#aaa;' : 'color: #67C23A;'">{{ scope.row.online_status
              == 0 ? '离线' : '在线'
              }}</div>
            </template>
          </el-table-column>
          <el-table-column label="最近登录时间" width="180">
            <template slot-scope="scope">
              {{ validDateTime(scope.row.last_logintime) }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="100">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)" v-if="isShowBtn('635b4fa6b10b00004400719d')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    </div>
    <!-- 新增对话框 -->
    <lebo-dialog append-to-body :title="title" :isShow="isAddDialog" width="55%" @close="closeAddDialogVisible()" footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" class="demo-ruleForm">
        <!-- <el-divider></el-divider> -->
        <div style="display: flex;justify-content: space-between;">
          <div style="width:80%;">
            <el-form-item label="用户名：" prop="account">
              <el-input v-model="addForm.account" maxlength="11" placeholder="请输入用户名称" @input="(e) => (addForm.account = validSpace(e))"></el-input>
            </el-form-item>
            <el-form-item label="密码：" prop="password">
              <el-input v-model="addForm.password" maxlength="15" placeholder="请输入密码" @input="(e) => (addForm.password = validSpace(e))"></el-input>
            </el-form-item>
            <el-form-item label="姓名：" prop="user_name">
              <el-input v-model="addForm.user_name" maxlength="15" placeholder="请输入姓名" @input="(e) => (addForm.user_name = validSpace(e))"></el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="phone">
              <el-input v-model="addForm.phone" placeholder="请输入手机号" @input="(e) => (addForm.phone = validSpace(e))" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item label="停车场：" prop="parking_name">
              <el-select popper-class="my-select" class="addForm-select" ref="selectRef" v-model="addForm.parking_name" placeholder="请选择停车场" @focus="selectFocus">
              </el-select>
            </el-form-item>
          </div>
          <el-form-item class="shop_img_url" prop="head_photo">
            <div class="img_box">
              <el-upload class="avatar-uploader" accept="image/jpeg,image/jpg,image/png" action="" :http-request="upload" :show-file-list="false" :before-upload="beforeAvatarUpload">
                <img v-if="addForm.head_photo" :src="addForm.head_photo" class="avatar" fit="cover">
                <span v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  上传照片
                </span>
              </el-upload>
              <span>注：该照片用于人脸识别，建议上传最近三个月正面免冠照，图片格式支持jpeg、jpg、png，文件大小小于5M</span>
            </div>
          </el-form-item>
        </div>
        <!-- <el-form-item label="人员照片：">
          <div class="logodiv">
            <el-image fit="cover" :src="addForm.head_photo" :preview-src-list="[addForm.head_photo]" :z-index="2500"
              v-if="addForm.head_photo">
            </el-image>
            <el-upload class="logo-upload" action="''" :http-request="fnUploadImg" :show-file-list="false"
              accept="image/*">
              <el-button size="small" type="warning">{{ addForm.head_photo ? "重新上传" : "上传" }}</el-button>
            </el-upload>
          </div>
        </el-form-item> -->
        <!-- <el-form-item label="考勤组：">
          <el-select popper-class="my-select" v-model="addForm.attendance_group_id" no-data-text="暂未配置考勤组" filterable
            clearable default-first-option placeholder="请选择考勤组">
            <el-option v-for="item in attendanceGroupList" :key="item._id" :label="item.attendance_group_name"
              :value="item._id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="停车场：">
          <el-select popper-class="my-select" class="addForm-select" ref="selectRef" v-model="addForm.parking_name"
            placeholder="请选择停车场" @focus="selectFocus">
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="监控：">
          <el-select popper-class="my-select" v-model="addForm.monitoring_name" placeholder="请选择监控场景"
            :popper-append-to-body="false" ref="selectedSceneids">
            <el-option class="setstyle" :value="addForm.monitoring_name">
              <el-tree :data="monitoringSelectList" :props="monitoringDefaultProps" show-checkbox
                :default-checked-keys="monitoring_sceneids" ref="tree" node-key="Id" :highlight-current="true"
                @check="handleNodeClick" default-expand-all style="height:100%;">
              </el-tree>
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAddDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
      </span>
      <!-- 选择停车场 -->
      <lebo-dialog append-to-body class="seleparking_box" title="选择停车场" :isShow="innerVisible" @close="closeSelectParking" footerSlot>
        <el-row :gutter="20" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-col :span="10">
            <el-tree style="max-height:550px;overflow:auto;" :data="inParkingList" ref="tree" @check-change="checkChange" @node-click="nodeClick" :default-checked-keys="checkParkingList" show-checkbox :props="defaultProps" node-key="parking_id"></el-tree>
          </el-col>
          <el-col :span="12">
            <div style="display: flex;margin-bottom:15px;">
              <el-input v-model="querySpacesForm.space_number" @keyup.enter.native="searchSpace()" placeholder="请输入车位编号"></el-input>
              <el-button type="primary" style="margin-left:15px;" @click="searchSpace">查询</el-button>
            </div>
            <!-- v-infinite-scroll="load" -->
            <el-table ref="spacesListTable" @selection-change="spacesListChange" @select-all="selectAll" @select="spacesItemChange" :data="spacesList" tooltip-effect="dark" border class="spaceslist_table">
              <el-table-column label="车位编号" prop="space_number" align="center" header-align="center" sortable>
              </el-table-column>
              <el-table-column type="selection" width="120" align="center" header-align="center"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <!-- <lbButton type="goBack" icon="back" @click="innerVisible = false">返 回</lbButton> -->
          <lbButton type="default" fill icon="confirm" @click="selectParking()">保 存</lbButton>
        </div>
      </lebo-dialog>
    </lebo-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState, mapGetters } from 'vuex'
import request from '@/api/systemapi'
import {
  getInspectorDetails, getOwnerParkingList, getInspectorList,
  delInspector, getParkingSpacesList, addInspector,
  updateInspector, getAttendanceGroupList, getRailStrategySelectList
} from '@/api/administrative'
export default {
  data () {
    // 自定义验证规则  用户密码
    var psdRule = (rule, value, callback) => {
      const psdReg = /^[\w]{8,14}$/
      if (psdReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    return {
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        account: '',
        user_name: '',
        phone: '',
        parking_id: '',
        online_status: null
      },
      // 考勤组
      attendanceGroupQueryList: [],
      // 围栏策略
      railStrategyQueryList: [],
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 新增对话框
      isAddDialog: false,
      title: '',
      // 新增表单
      addForm: {
        _id: '',
        account: '',
        password: '',
        parking_name: '',
        selected_parking: [],
        user_name: '',
        phone: '',
        remarks: '',
        head_photo: ''
      },
      monitoring_sceneids: [],
      // 新增表单验证
      addFormRules: {
        account: [
          { required: true, message: '请输入用户名称', trigger: 'blur' }
        ],
        head_photo: [{ required: true, message: '请上传用户头像', trigger: 'blur' }],
        parking_name: [{ required: true, message: '请选择停车场', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            validator: psdRule,
            message: ' 8~14个字符，包含字母、数字，下划线，区分大小写',
            trigger: 'blur'
          }
        ],
        selected_parking: [
          { type: 'array', required: true, message: '请选择停车场', trigger: 'blur' }
        ],
        user_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
          // { pattern: /^[\u4E00-\u9FA5]{2,4}$/, message: '格式错误', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号格式', trigger: 'blur' }
        ]
      },
      innerVisible: false,
      // 获取考勤组下拉列
      attendanceGroupList: [],
      // 获取当前登录人所拥有的停车场权限信息
      inParkingList: [],
      // 当前车位列表所属停车场id
      parkingId: '',
      // 车位信息列表
      spacesList: [],
      // 车位总数
      spacesTotalCount: 0,
      // 选中的车位信息列表
      spacesListSelection: [],
      defaultProps: {
        children: 'children',
        label: 'parking_name'
      },
      // 根据停车场获取车位信息
      querySpacesForm: {
        parking_id: '',
        space_number: '',
        operator_type: '',
        PageIndex: 1,
        PageSize: 50
      },
      // 所有停车场已选中的车位列表
      checkSpaceList: [],
      // 选择中的停车场
      checkParkingList: [],
      loading: false,
      monitoringSelectList: [], // 监控场景列表
      monitoringDefaultProps: {
        multiple: true,
        children: 'children',
        label: 'name',
        value: 'id'
      },
      parkPlaceList: [], // 选择时做存储用的停车场通道信息
      isMultiRow: true, // 查询下拉弹窗
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '用户名',
            prop: 'account'
          }, {
            label: '姓名',
            prop: 'user_name'
          }, {
            label: '手机号',
            prop: 'phone'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '状态',
            prop: 'online_status',
            slot: true
          }, {
            label: '最近登录时间',
            prop: 'last_logintime',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time'
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.onSubmit()
    // this.fnGetAllSelectList();
    // this.fnGetInspectorList();
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName', 'getUserInfo', 'getAllParkingList'])
  },
  mounted () {
  },
  watch: {
    loading: function (val, oldval) {
      // console.log('---loading---', val);
    }
  },
  methods: {
    // // 获取查询列表中考勤组和围栏策略下拉列
    // fnGetAllSelectList() {
    //   var that = this;
    //   axios.all([this.fnGetAttendanceGroupList(), this.fnGetRailStrategySelectList()]).then(axios.spread(
    //     function (attendanceGroup, railStrategy) {
    //       console.log('获取考勤组下拉列---', attendanceGroup);
    //       console.log('围栏策略下拉列----', railStrategy);
    //       if (attendanceGroup && attendanceGroup.Code === 200) {
    //         that.attendanceGroupQueryList = attendanceGroup.Data.DataList;
    //         that.attendanceGroupQueryList.unshift({
    //           attendance_group_name: '未配置',
    //           _id: 0,
    //         })
    //         that.attendanceGroupQueryList.unshift({
    //           attendance_group_name: '所有',
    //           _id: -1,
    //         })
    //       } else {
    //         that.attendanceGroupQueryList = [];
    //       }
    //       if (railStrategy && railStrategy.Code === 200) {
    //         that.railStrategyQueryList = railStrategy.Data;
    //         that.railStrategyQueryList.unshift({
    //           strategy: '未配置',
    //           ring_fence_id: 0,
    //         })
    //         that.railStrategyQueryList.unshift({
    //           strategy: '所有',
    //           ring_fence_id: -1,
    //         })
    //       } else {
    //         that.railStrategyQueryList = [];
    //       }
    //     }
    //   ))
    // },
    // async fnGetRailStrategySelectList() {
    //   return getRailStrategySelectList();
    // },
    // 获取巡检人员信息列表
    async fnGetInspectorList () {
      const res = await getInspectorList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        account: this.queryForm.account,
        user_name: this.queryForm.user_name,
        phone: this.queryForm.phone,
        parking_id: this.queryForm.parking_id,
        online_status: this.queryForm.online_status === 0 || this.queryForm.online_status ? this.queryForm.online_status : 3
      })
      if (res && res.Code === 200) {
        console.log('巡检人员信息----', res.Data)
        this.total = res.Data.TotalCount
        this.tableData = res.Data.DataList
        this.tableData.forEach(item => {
          item.berth_num = 0
          if (item.parking_name) {
            item.selected_parking.forEach(select => {
              // console.log(select.parking_spaces.length);
              item.berth_num += select.parking_spaces.length
              // console.log(item.berth_num);
            })
          }
        })
      } else {
        this.total = 0
        this.tableData = []
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetInspectorList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      //  // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetInspectorList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetInspectorList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetInspectorList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      //  // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetInspectorList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (val) {
    //   this.multipleSelection = val
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的巡检人员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$msg.success('删除成功!');
          var obj = {}
          var arr = []
          // this.$msg.success('删除成功!');
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fnDelInspector(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的收费员！')
      }
    },
    // 删除巡检人员请求
    async fnDelInspector (obj) {
      const res = await delInspector(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetInspectorList()
    },
    // 新增收费员
    openAddForm () {
      this.isAddDialog = true
      this.title = '新增收费员'
      // var that = this;
      // axios.all([this.fnGetAttendanceGroupList(), this.fnGetBindSceneTree()]).then(axios.spread(
      //   function (attendanceGroup, scene) {
      //     console.log('获取考勤组下拉列---', attendanceGroup);
      //     if (attendanceGroup && attendanceGroup.Code === 200) {
      //       that.attendanceGroupList = attendanceGroup.Data.DataList;
      //       that.addForm.attendance_group_id = attendanceGroup.Data.DataList.length > 0 ? attendanceGroup.Data.DataList[0]._id : '';
      //     }
      //     that.monitoringSelectList = scene && scene.Code === 200 ? scene.Data : [];
      //     console.log(scene);
      //   }
      // ))
    },
    // // 监控场景
    // handleNodeClick(data, node) {
    //   console.log('监控场景---', data);
    //   console.log(node);
    //   // console.log(node.checkedNodes);
    //   var checknode = node.checkedNodes.concat(node.halfCheckedNodes)
    //   var monitor_count = 0;
    //   checknode.forEach(item => {
    //     if (item.monitor_count) {
    //       monitor_count += item.monitor_count
    //     }
    //   })
    //   this.addForm.monitoring_name = '已选择' + monitor_count + '监控';
    //   var halfCheckedKeys = node.halfCheckedKeys;
    //   var checkedKeys = node.checkedKeys;
    //   this.addForm.monitoring_sceneids = halfCheckedKeys.concat(checkedKeys);
    // },
    // 修改人员
    openEditForm (row) {
      // console.log(row);
      this.isAddDialog = true
      this.title = '编辑收费员'
      var that = this
      axios.all([this.fnGetInspectorDetails(row._id)]).then(axios.spread(
        function (detail) {
          //  console.log('获取考勤组下拉列---', attendanceGroup);
          console.log('获取巡检人员详情----', detail)
          //   that.attendanceGroupList = attendanceGroup && attendanceGroup.Code === 200 ? attendanceGroup.Data.DataList : [];
          //  that.monitoringSelectList = scene && scene.Code === 200 ? scene.Data : [];
          // console.log(scene);
          if (detail && detail.Code === 200) {
            // that.addForm.attendance_group_id = attendanceGroup.Data.DataList.length > 0 ? attendanceGroup.Data.DataList[0]._id : '';
            // detail.Data.selected_parking.forEach(item => {
            //   that.checkParkingList.push(item.parking_id)
            // })
            // that.checkSpaceList = detail.Data.selected_parking
            that.addForm._id = detail.Data._id
            that.addForm.account = detail.Data.account
            that.addForm.password = detail.Data.password
            // that.addForm.parking_name = row.parking_name ? ((row.parking_name.split('、')).length <= 1 ? `${row.parking_name}（${row.berth_num}个车位）` : `${(row.parking_name.split('、')).length}个（${row.berth_num}个车位）`) : ''
            that.addForm.parking_name = row.parking_name ? ((row.parking_name.split('、')).length <= 1 ? row.berth_num > 0 ? `${row.parking_name}（${row.berth_num}个车位）` : `${row.parking_name}` : `${(row.parking_name.split('、')).length}个（${row.berth_num}个车位）`) : ''
            that.addForm.selected_parking = row.parking_name ? detail.Data.selected_parking : []
            // // that.checkSpaceList = row.parking_name ? detail.Data.selected_parking : []
            that.addForm.user_name = detail.Data.user_name
            that.addForm.phone = detail.Data.phone
            that.addForm.remarks = detail.Data.remarks
            // that.addForm.attendance_group_id = detail.Data.attendance_group_id !== "000000000000000000000000" ? detail.Data.attendance_group_id : ''
            that.addForm.head_photo = detail.Data.head_photo
            // that.addForm.monitoring_sceneids = detail.Data.monitoring_sceneids
            // that.monitoring_sceneids = detail.Data.monitoring_sceneids
          }
        }
      ))
    },
    // // 获取考勤组下拉列
    // async fnGetAttendanceGroupList() {
    //   return await getAttendanceGroupList({
    //     PageSize: 0,
    //     PageIndex: 0,
    //   });
    // },
    // // 获取监控场景树
    // async fnGetBindSceneTree() {
    //   return await getBindScene()
    // },
    // 获取巡检人员详情
    async fnGetInspectorDetails (id) {
      return await getInspectorDetails({
        inspector_id: id
      })
    },
    // 当停车场下拉框获得焦点时， 展示选择停车场对话框并获取当前登录人所拥有的停车场权限信息
    selectFocus (e) {
      console.log('=------------==selectFocus-=-=-=========')
      // console.log('-this.addForm.selected_parking---1---', this.addForm.selected_parking);
      // console.log('-this.addForm.parking_name', this.addForm.parking_name);
      this.innerVisible = true
      this.checkSpaceList = []
      this.checkParkingList = []
      this.inParkingList = []
      this.spacesList = []
      this.parkPlaceList = []
      this.querySpacesForm.space_number = ''
      if (this.addForm.selected_parking.length > 0) {
        this.checkSpaceList = this.addForm.selected_parking
        this.parkingId = this.addForm.selected_parking[0].parking_id
        this.addForm.selected_parking.forEach(item => {
          this.checkParkingList.push(item.parking_id)
        })
        // console.log('--this.addForm.selected_parking --3--', this.addForm.selected_parking);
      } else {
        this.addForm.selected_parking = []
      }
      // console.log(this.addForm.selected_parking);
      this.fnGetOwnerParkingList()
      this.$refs.selectRef.blur()
    },
    // 获取当前登录人所拥有的停车场权限信息
    async fnGetOwnerParkingList () {
      this.loading = true
      const res = await getOwnerParkingList()
      // console.log('停车场列表----' + JSON.stringify(res));
      if (res && res.Code === 200) {
        this.inParkingList = res.Data
        // 若已绑定了停车场，则默认获取绑定的第一个停车场的数据，否则默认获取第一个停车场的车位列表
        if (res.Data.length > 0) {
          this.querySpacesForm.parking_id = this.parkingId ? this.parkingId : res.Data[0].parking_id
          this.parkingId = this.parkingId ? this.parkingId : res.Data[0].parking_id
          this.querySpacesForm.PageIndex = 1
          var that = this
          if (this.title === '新增收费员') {
            this.fnGetParkingSpacesList(0).then((data) => {
              that.setData(0, data)
            })
          } else {
            axios.all([this.fnGetParkingSpacesList(2), this.fnGetParkingSpacesList(1)]).then(axios.spread(function (acct, perms) {
              that.setData(2, acct)
              that.setData(1, perms)
            }))
          }
        } else {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    // 节点被点击时的回调
    nodeClick (key, node, data) {
      this.loading = true
      // console.log(key, node, data);
      this.querySpacesForm.parking_id = key.parking_id
      this.parkingId = key.parking_id
      this.querySpacesForm.PageIndex = 1
      this.spacesList = []
      var that = this
      if (this.title === '新增收费员') {
        this.fnGetParkingSpacesList(0).then((data) => {
          that.setData(0, data)
        })
      } else {
        console.log('this.title', this.title)

        axios.all([this.fnGetParkingSpacesList(2), this.fnGetParkingSpacesList(1)]).then(axios.spread(function (acct, perms) {
          that.setData(2, acct)
          that.setData(1, perms)
        }))
      }
    },
    // 根据停车场获取车位信息
    fnGetParkingSpacesList (type) {
      // 当 type=0 时，获取停车场所有未绑定巡检人员的车位
      // 当 type=1 时，获取停车场巡检人员未绑定车位
      // 当 type=2 时，获取停车场该巡检人员已绑定车位
      return getParkingSpacesList({
        parking_id: this.querySpacesForm.parking_id,
        inspector_id: type === 0 ? '' : this.addForm._id,
        space_number: this.querySpacesForm.space_number,
        operator_type: type,
        PageIndex: type !== 2 ? this.querySpacesForm.PageIndex : 0,
        PageSize: type !== 2 ? this.querySpacesForm.PageSize : 0
      })
    },
    setData (type, res) {
      // console.log('-this.addForm.selected_parking--1111---', this.addForm.selected_parking);
      // console.log('车位列表----' + JSON.stringify(res));
      // 当 type=0 时，获取停车场所有未绑定巡检人员的车位
      // 当 type=1 时，获取停车场巡检人员未绑定车位
      // 当 type=2 时，获取停车场该巡检人员已绑定车位
      if (type === 2) {
        this.spacesList = this.spacesList.concat(res.Data.DataList)
        this.loading = false
      } else if (type === 1) {
        this.spacesTotalCount = res.Data.TotalCount
        this.spacesList = this.spacesList.concat(res.Data.DataList)
        // console.log('编辑已获取车位数：' + this.spacesList.length);
        // console.log('编辑总车位数：' + this.spacesTotalCount);
        if (this.spacesList.length < this.spacesTotalCount) {
          this.querySpacesForm.PageIndex++
          var that = this
          this.fnGetParkingSpacesList(1).then((data) => {
            that.setData(1, data)
          })
        } else {
          this.fntoggleRowSelection()
        }
      } else if (type === 0) {
        this.spacesTotalCount = res.Data.TotalCount
        this.spacesList = this.spacesList.concat(res.Data.DataList)
        // console.log('新增已获取车位数：' + this.spacesList.length);
        // console.log('新增总车位数：' + this.spacesTotalCount);
        if (this.spacesList.length < this.spacesTotalCount) {
          this.querySpacesForm.PageIndex++
          var _that = this
          this.fnGetParkingSpacesList(0).then((data) => {
            _that.setData(0, data)
          })
        } else {
          this.fntoggleRowSelection()
          this.loading = false
        }
      }
    },
    fntoggleRowSelection () {
      // 若已选中的车位列表中有数据，则筛选出属于当前停车场的数据，并赋值给已选中的车位信息列表
      if (this.checkSpaceList.length > 0) {
        var arr = this.checkSpaceList.concat()
        arr = arr.filter(item => {
          return item.parking_id === this.parkingId
        })
        if (arr[0]) {
          var newArr = arr[0].parking_spaces
          // 从车位信息列表中筛选出已选中的数据
          newArr.forEach(row => {
            // console.log('row----', row);
            this.spacesList.forEach(item => {
              // console.log('item---', item);
              var id = ''
              id = row.parking_space_id ? row.parking_space_id : row._id
              if (id === item._id) {
                // 在选中的时候，DOM渲染不及，导致无法选中；此时可以使用this.$nextTick()
                this.$nextTick(() => {
                  this.$refs.spacesListTable.toggleRowSelection(item, true)
                  this.loading = false
                })
              }
            })
          })
        }
      } else {
        this.loading = false
      }
    },
    // // 下拉加载更多车位数据
    // load() {
    // console.log(this.spacesList);
    //   // 若还有数据，则页码值++;获取更多数据
    //   if (this.spacesList.length < this.spacesTotalCount) {
    //     var that = this
    //     if (this.title === '新增收费员') {
    //       this.querySpacesForm.PageIndex++
    //       this.fnGetParkingSpacesList(0).then((data) => {
    //         that.setData(0, data)
    //       })
    //     } else {
    //       this.querySpacesForm.PageIndex++
    //       axios.all([this.fnGetParkingSpacesList(2), this.fnGetParkingSpacesList(1)]).then(axios.spread(function (acct, perms) {
    //         that.setData(2, acct)
    //         that.setData(1, perms)
    //       }));
    //     }
    //   } else {
    //     return
    //   }
    // },
    // 搜索车位
    searchSpace () {
      if (!this.querySpacesForm.parking_id) return
      this.spacesList = []
      // console.log(this.querySpacesForm);
      this.querySpacesForm.PageIndex = 1
      var that = this
      if (this.title === '新增收费员') {
        this.fnGetParkingSpacesList(0).then((data) => {
          // console.log(data);
          that.setData(0, data)
        })
      } else {
        axios.all([this.fnGetParkingSpacesList(2), this.fnGetParkingSpacesList(1)]).then(axios.spread(function (acct, perms) {
          that.setData(2, acct)
          that.setData(1, perms)
        }))
      }
    },
    // 停车场节点选中状态发生变化时的回调
    checkChange (key, node, data) {
      // console.log(key);
      // console.log('-this.addForm.selected_parking---2---', this.parkingId);
      this.loading = true
      if (this.parkingId === key.parking_id) {
        this.checkAll(node)
      } else {
        // this.parkingId = key.parking_id
        this.querySpacesForm.parking_id = key.parking_id
        this.parkingId = key.parking_id
        this.spacesList = []
        var that = this
        if (this.title === '新增收费员') {
          this.fnAGetAllParkingSpacesList({
            parking_id: this.querySpacesForm.parking_id,
            inspector_id: '',
            space_number: this.querySpacesForm.space_number,
            operator_type: 0,
            PageIndex: 0,
            PageSize: 0
          }).then((data) => {
            // this.spacesList = this.spacesList.concat(res.Data.DataList)
            that.setAllData(0, data, node)
          })
        } else {
          axios.all([this.fnAGetAllParkingSpacesList({
            parking_id: this.querySpacesForm.parking_id,
            inspector_id: this.addForm._id,
            space_number: this.querySpacesForm.space_number,
            operator_type: 2,
            PageIndex: 0,
            PageSize: 0
          }), this.fnAGetAllParkingSpacesList({
            parking_id: this.querySpacesForm.parking_id,
            inspector_id: this.addForm._id,
            space_number: this.querySpacesForm.space_number,
            operator_type: 1,
            PageIndex: 0,
            PageSize: 0
          })]).then(axios.spread(function (acct, perms) {
            that.setAllData(2, acct, node)
            that.setAllData(1, perms, node)
          }))
        }
      }
    },
    fnAGetAllParkingSpacesList (obj) {
      return getParkingSpacesList(obj)
    },
    setAllData (type, res, node) {
      if (res.Data.DataList.length > 0) {
        // console.log('车位列表----' + JSON.stringify(res));
        // 当 type=0 时，获取停车场所有未绑定巡检人员的车位
        // 当 type=1 时，获取停车场巡检人员未绑定车位
        // 当 type=2 时，获取停车场该巡检人员已绑定车位
        if (type === 2) {
          this.spacesList = this.spacesList.concat(res.Data.DataList)
          this.checkAll(node)
        } else if (type === 1) {
          this.spacesTotalCount = res.Data.TotalCount
          this.spacesList = this.spacesList.concat(res.Data.DataList)
          this.checkAll(node)
        } else if (type === 0) {
          this.spacesTotalCount = res.Data.TotalCount
          this.spacesList = this.spacesList.concat(res.Data.DataList)
          this.checkAll(node)
        }
      } else {
        this.loading = false
      }
    },
    checkAll (node) { // 选择车场
      // console.log(222)
      //  // console.log('-this.addForm.selected_parking--2-', this.addForm.selected_parking);
      // 选中
      if (node) {
        // console.log(this.spacesList)
        if (this.spacesListSelection.length === 0) {
          // console.log(this.spacesList);
          // console.log('-this.addForm.selected_parking--all--1-', this.addForm.selected_parking);
          if (this.spacesList.length > 0) {
            this.spacesList.forEach(item => {
              // console.log(item);
              this.$nextTick(() => {
                this.$refs.spacesListTable.toggleRowSelection(item, true)
                this.loading = false
                // this.addForm.selected_parking = []
              })
            })
            // console.log('-this.addForm.selected_parking--2-', this.addForm.selected_parking);
            if (this.checkSpaceList.length > 0) {
              this.checkSpaceList = this.checkSpaceList.filter(item => {
                return item.parking_id !== this.parkingId
              })
            }
            this.checkSpaceList.push({
              parking_id: this.parkingId,
              parking_spaces: this.spacesList
            })
          } else {
            this.loading = false
          }
        } else {
          this.loading = false
          // this.addForm.selected_parking = []
        }
        // 设置车位表格全部选中
        // this.$refs.spacesListTable.toggleAllSelection()
      } else { // 取消选中
        // this.checkSpaceList = this.checkSpaceList.filter(item => {
        //   return item.parking_id === this.parkingId
        // })
        this.$refs.spacesListTable.clearSelection()
        if (this.checkSpaceList.length > 0) {
          this.checkSpaceList = this.checkSpaceList.filter(item => {
            return item.parking_id !== this.parkingId
          })
        }
        if (this.checkParkingList.length > 0) {
          this.checkParkingList = this.checkParkingList.filter(item => {
            return item !== this.parkingId
          })
        }
        this.loading = false
        // console.log(this.checkParkingList);
      }
      // console.log(this.checkSpaceList);
    },

    // 当车位信息列表选中框发生改变时触发，
    spacesListChange (val) {
      // console.log(val);
      // this.spacesListSelection = val
      // // 当表格选中项中有数据时，判断是否在所有停车场已选中的车位列表中，若在，则删除之前的再重新添加，不在则直接添加;
      // // 当表格全部取消选中时，则清除当前停车场中已选中的车位列表，清除选中的停车场树
      // if (this.spacesListSelection.length > 0) {
      //   // console.log(val)
      //   // 若选中的停车场中有值，则筛选出不为当前停车场的数据
      //   if (this.checkParkingList.length > 0) {
      //     this.checkParkingList = this.checkParkingList.filter(item => {
      //       return item !== this.parkingId
      //     })
      //   }
      //   // 将当前停车场加入选中的停车场中
      //   this.checkParkingList.push(this.parkingId)
      //   // 设置选中的停车场树
      //   this.$refs.tree.setCheckedKeys(this.checkParkingList)
      //   // 若所有停车场选中车位列表中有数据，则筛选出不为当前停车场的数据，并重新添加进去
      //   if (this.checkSpaceList.length > 0) {
      //     this.checkSpaceList = this.checkSpaceList.filter(item => {
      //       return item.parking_id !== this.parkingId
      //     })
      //   }
      //   this.checkSpaceList.push({
      //     parking_id: this.parkingId,
      //     parking_spaces: this.spacesListSelection
      //   })
      // } else {
      //   // if (this.checkParkingList.length > 0) {
      //   //   this.checkParkingList = this.checkParkingList.filter(item => {
      //   //     return item !== this.parkingId
      //   //   })
      //   // }
      //   // // console.log(this.checkParkingList);
      //   // this.$refs.tree.setCheckedKeys(this.checkParkingList)
      //   // // 若所有停车场选中车位列表中有数据，则筛选出不为当前停车场的数据
      //   // if (this.checkSpaceList.length > 0) {
      //   //   this.checkSpaceList = this.checkSpaceList.filter(item => {
      //   //     return item.parking_id !== this.parkingId
      //   //   })
      //   // }
      // }
    },
    spacesItemChange (selection, row) {
      // !--
      // this.checkSpaceList = []
      // console.log(this.spacesListSelection)
      console.log('selection=====', selection)
      // console.log('row-----', row)
      if (selection.length === 0) {
        this.spacesListSelection = this.spacesListSelection.filter(item => item._id === row._id)
        var j = this.checkSpaceList.findIndex(item => item.parking_id === this.parkingId)
        var newArr = this.checkSpaceList[j].parking_spaces.concat()
        newArr = newArr.filter(item => item._id !== row._id)
        if (newArr.length > 0) {
          this.checkSpaceList[j].parking_spaces = newArr
        } else {
          this.checkParkingList = this.checkParkingList.filter(item => {
            return item !== this.parkingId
          })
          // console.log(this.checkParkingList);
          this.$refs.tree.setCheckedKeys(this.checkParkingList)
          this.checkSpaceList.splice(j, 1)
        }
        // arr = arr.filter(item => item._id !== row._id);
        // this.checkSpaceList[j].parking_spaces = arr;
        // if (this.spacesListSelection.length > 0) {
        //   this.spacesListSelection = this.spacesListSelection.filter(item => {
        //     return item._id !== row._id
        //   })
        //   if (this.spacesListSelection.length === 0) {
        //     if (this.checkParkingList.length > 0) {
        //       this.checkParkingList = this.checkParkingList.filter(item => {
        //         return item !== this.parkingId
        //       })
        //     }
        //     // console.log(this.checkParkingList);
        //     this.$refs.tree.setCheckedKeys(this.checkParkingList)
        //     // 若所有停车场选中车位列表中有数据，则筛选出不为当前停车场的数据
        //     if (this.checkSpaceList.length > 0) {
        //       this.checkSpaceList = this.checkSpaceList.filter(item => {
        //         return item.parking_id !== this.parkingId
        //       })
        //     }
        //   } else {
        //     // 若所有停车场选中车位列表中有数据，则筛选出不为当前停车场的数据，并重新添加进去
        //     if (this.checkSpaceList.length > 0) {
        //       this.checkSpaceList = this.checkSpaceList.filter(item => {
        //         return item.parking_id !== this.parkingId
        //       })
        //     }
        //     this.checkSpaceList.push({
        //       parking_id: this.parkingId,
        //       parking_spaces: this.spacesListSelection
        //     })
        //   }
        // }
      } else {
        var item = selection.find(item => item === row)
        if (item) { // 选中
          this.spacesListSelection = selection
          // 将当前停车场加入选中的停车场中
          this.checkParkingList.push(this.parkingId)
          this.checkParkingList = [...new Set(this.checkParkingList)]
          // 设置选中的停车场树
          this.$refs.tree.setCheckedKeys(this.checkParkingList)
          var index = this.checkSpaceList.findIndex(data => data.parking_id === this.parkingId)
          if (index < 0) {
            this.checkSpaceList.push({
              parking_id: this.parkingId,
              parking_spaces: this.spacesListSelection
            })
          } else {
            this.checkSpaceList[index].parking_spaces.push(row)
          }
        } else { // 取消选中
          this.spacesListSelection = this.spacesListSelection.filter(item => item._id === row._id)
          var i = this.checkSpaceList.findIndex(item => item.parking_id === this.parkingId)
          var arr = this.checkSpaceList[i].parking_spaces.concat()
          arr = arr.filter(item => item._id !== row._id)
          this.checkSpaceList[i].parking_spaces = arr
        }
      }
    },
    // 当选择全选停车场车位列表时
    selectAll (row) {
      // console.log(row);
      // 若全选车位列表数据，则将当前获取到的数据都添加到所有停车场已选中的车位列表
      if (row.length > 0) {
        // 若选中的停车场中有值，则筛选出不为当前停车场的数据
        if (this.checkParkingList.length > 0) {
          this.checkParkingList = this.checkParkingList.filter(item => {
            return item !== this.parkingId
          })
        }
        this.$refs.tree.setChecked(this.parkingId)
        // 将当前停车场加入选中的停车场中
        this.checkParkingList.push(this.parkingId)
        // 设置选中的停车场树
        this.$refs.tree.setCheckedKeys(this.checkParkingList)
        // 若所有停车场选中车位列表中有数据，则筛选出不为当前停车场的数据，并重新添加进去
        if (this.checkSpaceList.length > 0) {
          this.checkSpaceList = this.checkSpaceList.filter(item => {
            return item.parking_id !== this.parkingId
          })
        }
        this.checkSpaceList.push({
          parking_id: this.parkingId,
          parking_spaces: row
        })
      } else { // 取消全选
        // 若选中的停车场中有值，则筛选出不为当前停车场的数据
        if (this.checkParkingList.length > 0) {
          this.checkParkingList = this.checkParkingList.filter(item => {
            return item !== this.parkingId
          })
        }
        // console.log(this.checkParkingList);
        this.$refs.tree.setCheckedKeys(this.checkParkingList)
        // 若所有停车场选中车位列表中有数据，则筛选出不为当前停车场的数据
        if (this.checkSpaceList.length > 0) {
          this.checkSpaceList = this.checkSpaceList.filter(item => {
            return item.parking_id !== this.parkingId
          })
        }
      }
    },
    // 选择停车场完成后，点击确定触发
    // selectParking () {
    //   console.log('选择的车位', this.checkSpaceList)
    //   console.log('选择的车场', this.$refs.tree.getCheckedNodes())
    //   if (this.checkSpaceList.length > 0) {
    //     var newArr = this.$refs.tree.getCheckedNodes()
    //     console.log('newArr', newArr)
    //     let total = 0
    //     var arr = []
    //     this.checkSpaceList.forEach(item => {
    //       // var newArr = []
    //       var oldArr = item.parking_spaces
    //       total += Number(oldArr.length)
    //       // oldArr.forEach(data => {
    //       //   newArr.push({
    //       //     parking_space_id: data._id
    //       //   })
    //       // })
    //       arr.push({
    //         parking_id: item.parking_id,
    //         parking_spaces: oldArr
    //       })
    //     })
    //     this.addForm.selected_parking = arr
    //     // console.log('--this.addForm.selected_parking --select--', this.addForm.selected_parking);
    //     // this.addForm.parking_name = this.checkSpaceList.length === 1 ? `${newArr[0].parking_name}（${total}个车位）` : `${this.checkSpaceList.length}个（${total}个车位）`
    //     this.addForm.parking_name = this.checkSpaceList.length === 1 ? `${newArr[0].parking_name}（${total}个车位）` : `${this.checkSpaceList.length}个（${total}个车位）`
    //     this.innerVisible = false
    //   } else {
    //     // this.innerVisible = false
    //     this.$msg.info('未配置巡检车位')
    //     this.addForm.parking_name = ''
    //     this.addForm.selected_parking = []
    //     this.checkSpaceList = []
    //     this.checkParkingList = []
    //   }
    //   // console.log('-this.addForm.selected_parking--queding-', this.addForm.selected_parking);
    // },
    // test---------------------------------------------------------------
    selectParking () {
      console.log('选择的车位', this.checkSpaceList)
      console.log('选择的车场', this.$refs.tree.getCheckedNodes())
      console.log('停车场数据', this.checkParkingList)
      var newArr = this.$refs.tree.getCheckedNodes()
      // console.log('newArr', newArr)
      let filterNoSpaceList = newArr // 未选择车位的停车场
      let total = 0
      // var arr = []
      this.parkPlaceList = []
      if (this.checkSpaceList.length > 0) {
        this.checkSpaceList.forEach(item => {
          var oldArr = item.parking_spaces
          total += Number(oldArr.length)
          if (item.parking_spaces.length > 0) {
            this.parkPlaceList.push({
              parking_id: item.parking_id,
              parking_spaces: oldArr
            })
          }
        })
        // 筛选路外车场
        filterNoSpaceList = this.$refs.tree.getCheckedNodes().filter(element => {
          return element.parktype === 2
        })
        console.log('filterNoSpaceList', filterNoSpaceList)
      }
      // console.log('停车场数据2', this.parkingId)
      // arr = []
      filterNoSpaceList.forEach((item, index) => {
        this.parkPlaceList.push({
          // parking_id: newArrIdList[index],
          parking_id: item.parking_id,
          parking_spaces: []
        })
      })

      console.log(' this.parkPlaceList', this.parkPlaceList)
      this.addForm.selected_parking = this.parkPlaceList
      // this.addForm.parking_name = this.checkSpaceList.length === 1 ? `${newArr[0].parking_name}（${total}个车位）` : `${newArr.length}个（${total}个车位）`
      this.addForm.parking_name = total > 0 ? `${newArr.length}个车场（${total}个车位）` : `${newArr.length}个车场`
      this.innerVisible = false
    },
    // -------------------------------------------------------
    closeSelectParking () {
      this.innerVisible = false
      this.loading = false
      // console.log('-this.addForm.selected_parking--close-', this.addForm.selected_parking);
    },
    // // 触发选择图片按钮
    // fnUploadImg(item) {
    //   this.loading = true;
    //   const indexpoint = item.file.name.lastIndexOf(".");
    //   const imageName = item.file.name.substr(indexpoint);
    //   const src = window.URL.createObjectURL(item.file);
    //   var reader = new FileReader();
    //   reader.onload = async (e) => {
    //     // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
    //     // 使用axios的post方法上传即可
    //     var base64image = e.target.result;
    //     // console.log("==-=-base64image=-=-", base64image);
    //     const res = await request.updataIcon({ name: item.file.name, strbase64: [base64image] });
    //     var url = '';
    //     if (res.data && res.data.length > 0) {
    //       url = res.data[0].FilePath;
    //     } else {
    //       this.$msg.warning('你的图片格式有误请重新选择!');
    //     }
    //     this.addForm.head_photo = url;
    //   };
    //   this.loading = false;
    //   reader.readAsDataURL(item.file);
    // },
    // 触发选择图片按钮
    upload (item) {
      this.isLoading = true
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      const src = window.URL.createObjectURL(item.file)
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        // console.log("==-=-base64image=-=-", base64image);
        const res = await request.updataIcon({ name: item.file.name, strbase64: [base64image] })
        var url = ''
        if (res.data && res.data.length > 0) {
          url = res.data[0].FilePath
        } else {
          this.$msg.warning('你的图片格式有误请重新选择!')
        }
        this.addForm.head_photo = url
        this.isLoading = false
      }
      reader.readAsDataURL(item.file)
    },
    // 关闭新增编辑对话框，重置数据
    closeAddDialogVisible () {
      // this.isAddDialog = false
      this.$refs.addFormRef.resetFields()
      this.addForm.remarks = ''
      this.addForm._id = ''
      this.checkParkingList = []
      this.inParkingList = []
      this.parkingId = ''
      this.spacesList = []
      this.checkSpaceList = []
      this.spacesListSelection = []
      this.addForm.selected_parking = []
      this.addForm.head_photo = ''
      this.parkPlaceList = []
      this.isAddDialog = false
      // this.addForm.monitoring_sceneids = [];
      // this.addForm.monitoring_name = '';
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var newArr = []
          // console.log('--this.addForm.selected_parking --2--', this.addForm.selected_parking)
          this.addForm.selected_parking.forEach(item => {
            // console.log(item);
            var arr = []
            item.parking_spaces.forEach(space => {
              arr.push({
                parking_space_id: space.parking_space_id ? space.parking_space_id : space._id
              })
            })
            newArr.push({
              parking_id: item.parking_id,
              parking_spaces: arr
            })
          })
          var obj = {}
          obj = {
            account: this.addForm.account,
            password: this.addForm.password,
            selected_parking: newArr,
            user_name: this.addForm.user_name,
            phone: this.addForm.phone,
            remarks: this.addForm.remarks,
            // attendance_group_id: this.addForm.attendance_group_id,
            head_photo: this.addForm.head_photo
            // monitoring_sceneids: this.addForm.monitoring_sceneids,
          }
          if (this.title === '新增收费员') {
            console.log('新增停车员', obj)
            this.fnAddInspector(obj)
          } else {
            // console.log('ssssssssssssssss' + this.title);
            obj._id = this.addForm._id
            console.log('修改停车员', obj)
            this.fnUpdateInspector(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增巡检人员请求
    async fnAddInspector (obj) {
      const res = await addInspector(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.isAddDialog = false
        // this.fnGetInspectorList()
        this.onSubmit()
      }
    },
    //  修改巡检人员请求
    async fnUpdateInspector (obj) {
      const res = await updateInspector(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.fnGetInspectorList()
      }
    },
    // 下拉查询
    toggleChange (flag) {
      // console.log(flag)
      this.isMultiRow = flag
    }
  }
}
</script>

<style scoped lang="less">
.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 570px;
  }
}
.ParkingInformation{
  margin-top: 20px;
}
.spaceslist_table {
  width: 100%;

  /deep/ .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: auto;
  }
}

.demo-ruleForm {
  .addForm-select {
    width: 100%;

    /deep/ .el-input__suffix,
    .el-select-dropdown {
      display: none;
    }
  }

  .el-select {
    width: 100%;
  }

  .seleparking_box {
    .el-dialog__body {
      height: 500px;
    }
  }
}

.logodiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;

  .el-image {
    width: 150px;
    height: 150px;
    margin-right: 10px;
  }

  .logo-upload {
    display: flex;
    align-items: center;
  }
}

.shop_img_url {
  /deep/ .el-form-item__content {
    line-height: 1.3;
  }
}

.img_box {
  width: 180px;
  height: 180px;

  /deep/ .avatar-uploader {
    width: inherit;
    height: inherit;
    margin-bottom: 22px;

    .el-upload {
      width: inherit;
      height: inherit;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        width: inherit;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
        }
      }
    }

    // .el-upload:hover {
    //   border-color: #409eff;
    // }
  }

  .avatar {
    width: inherit;
    height: inherit;
    object-fit: cover; //重要
    font-family: "object-fit: cover;";
  }
}

// .demo-ruleForm {
//   max-height: 700px;
//   overflow: hidden;
//   overflow-y: auto;
//   padding-right: 10px;
// }

.setstyle {
  min-height: 200px;
  padding: 0 !important;
  margin: 0;
  overflow: auto;
  cursor: default !important;
}
.queryBox {
  margin-bottom: 20px;

}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
    width: 200px!important;
  }
</style>
